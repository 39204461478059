.home {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.home-paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-logo {
  margin: 60px;
  width: 200px;
}

.home-main {
  margin-bottom: 40px;
}

.home-mint-button {
  width: 250px;
  margin: 0;
  padding: 14px;
  background-color: white;
  color: rgba(105, 171, 167, 1);
  border: none;
  border-radius: 65px;
  cursor: pointer;
  font-size: 28px;
  font-family: Cookies;
}

@media (max-width: 760px) {
  .home-logo {
    margin: 30px;
    width: 150px;
  }

  .home-main {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .home-mint-button {
    width: 200px;
    font-size: 22px;
  }
}
