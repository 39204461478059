@font-face {
  font-family: 'Cookies';
  src: url('../src/fonts/Cookies.ttf') format('truetype');
}

@font-face {
  font-family: 'Schoolbell';
  src: url('../src/fonts/Schoolbell-Regular.ttf') format('truetype');
}

body {
  background: rgba(105, 171, 167, 1);
}

.scroll-top-button {
  position: fixed;
  bottom: 5%;
  right: 5%;
  height: 40px;
  background-color: #3333332e;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.7s ease;
}

.scroll-top-button:hover {
  background-color: #3333337c;
}

@media (max-width: 760px) {
  .scroll-top-button {
    height: 30px;
  }
}
