.mint-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.mint-container h1 {
  margin-top: 50px;
  font-family: Cookies;
}

.options-container {
  display: flex;
  font-family: Schoolbell;
  font-size: 24px;
  position: relative;
  margin: 10px;
}

.option {
  cursor: pointer;
}

.option:not(:last-child) {
  margin: 0 30px;
}

.underline {
  position: absolute;
  bottom: -3px;
  width: 100px;
  height: 3px;
  background-color: #fff;
}

.underline.public {
  left: 30px;
}

.underline.half {
  right: 40px;
}

.info-button {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  background-color: white;
  color: rgba(105, 171, 167, 1);
  border: 1px solid white;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 13px;

  position: relative;
  top: -18px;
}

.info-text {
  visibility: hidden;
  width: 260px;
  color: rgba(105, 171, 167, 1);
  text-align: center;
  padding: 15px;
  border-radius: 20px;
  font-size: 16px;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: -95px;
  left: 105%;

  opacity: 0;
  transition: opacity 0.3s;
}

.info-button:hover .info-text {
  visibility: visible;
  opacity: 1;
}

.mint-details-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-top: 20px;
  font-family: Schoolbell;
  font-size: 24px;
}

.monster-box {
  width: 220px;
  border-radius: 20px;
  margin: 30px;
}

.detail-column {
  width: 270px;
  height: 70px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
}

.number-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.number-buttons span {
  margin: 0 15px;
}

.number-buttons button {
  border: 2px solid white;
  border-radius: 50%;
  background-color: rgba(105, 171, 167, 1);
  color: white;
  height: 35px;
  width: 35px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.number-buttons button:hover {
  background-color: rgb(48, 106, 102);
}

.mint-now-button {
  width: 250px;
  margin-top: 20px;
  margin-bottom: 60px;
  padding: 14px;
  background-color: white;
  color: rgba(105, 171, 167, 1);
  border: none;
  border-radius: 65px;
  cursor: pointer;
  font-size: 28px;
  font-family: Cookies;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 350px;
  height: 200px;
  background-color: #fff;
  padding: 20px;
  border-radius: 18px;
  text-align: center;
  border: 2px solid rgba(105, 171, 167, 1);
  color: rgba(105, 171, 167, 1);
}

.modal-content h2 {
  font-family: Cookies;
  font-size: 30px;
}

.modal-content p {
  font-family: Schoolbell;
  font-size: 24px;
}

.modal-content button {
  color: rgba(105, 171, 167, 1);
  background-color: rgb(212, 212, 212);
  font-family: Schoolbell;
  font-size: 20px;
  width: 220px;
  padding: 7px;
  border: 2px solid rgba(105, 171, 167, 1);
  border-radius: 50px;
  cursor: pointer;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid rgba(105, 171, 167, 1);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

.loading-text {
  color: white;
  margin-top: 10px;
  font-size: 28px;
  font-family: Schoolbell;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 760px) {
  .mint-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }

  .underline.half {
    right: 25px;
  }

  .monster-box {
    width: 180px;
  }

  .info-button {
    display: none;
  }

  .mint-now-button {
    width: 200px;
    font-size: 22px;
  }

  .modal-content {
    width: 230px;
    height: 180px;
  }
}
