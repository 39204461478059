.about-paragraph {
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  background-color: white;
}

.about-text {
  width: 50%;
  margin: 60px 0;
}

.about-text h1 {
  font-family: Cookies;
  color: rgba(105, 171, 167, 1);
}

.about-text p {
  font-size: 25px;
  line-height: 1.9;
  font-family: Schoolbell;
}

@media (max-width: 760px) {
  .about-text {
    width: 70%;
  }
  .about-text h1 {
    font-size: 24px;
    margin: 50px 0;
  }

  .about-text p {
    font-size: 20px;
  }
}
