.connect-button {
  position: absolute;
  top: 70px;
  right: 10%;

  width: 200px;
  padding: 7px;
  background-color: rgba(105, 171, 167, 1);
  color: white;
  border: 2px solid white;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
  font-family: Schoolbell;
}

@media (max-width: 760px) {
  .connect-button {
    display: none;
  }
}
