.social {
  display: flex;
  justify-content: center;
  background-color: white;
  height: 180px;
}

.social-paragraph {
  display: flex;
  align-items: center;
}

.social-paragraph div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-paragraph img {
  display: block;
  height: 60px;
  margin: 30px 30px 0 30px;
  cursor: pointer;
}

.social-paragraph p {
  color: rgba(105, 166, 171, 1);
  font-size: 20px;
  cursor: pointer;
  font-family: Schoolbell;
}

@media (max-width: 760px) {
  .social-paragraph img {
    height: 50px;
    margin: 25px 25px 0 25px;
  }

  .social-paragraph p {
    font-size: 18px;
  }
}
